import AuthenticationButtons from "./auth/AuthenticationButton";
import "@fontsource/oswald"; // Oswald font used in logo
import downArrow from "../images/downArrow.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import FesLogo from "./Logo";
import styles from "../styles/_topBar.module.scss";


type NavMenuItemProps = {
  text: string;
  target?: { _type: "path"; uri: string } | { _type: "url"; uri: string };
  onClick: React.MouseEventHandler<HTMLElement>;
  childItems?: {
    text: string;
    target?: { _type: "path"; uri: string } | { _type: "url"; uri: string };
  }[];
};

function NavMenuItem(props: NavMenuItemProps) {
  const navigate = useNavigate();
  return (
    <li className={styles.dropdownMenu}>
      {props.target?._type === "url" && (
        <a
          href={props.target?.uri}
          target="_blank"
          rel="noreferrer"
          onClick={props.onClick}
        >
          <h3>
            {props.text}
            {props.childItems && props.childItems.length > 0 ? (
              <img src={downArrow} />
            ) : (
              ""
            )}
          </h3>
        </a>
      )}
      {!(props.target?._type === "url") && (
        <a
          href={props.target?.uri}
          // target="_blank"
          onClick={props.onClick}
        >
          <h3
            // onClick={(event) => {
            //   if (props.target?._type === 'path') {
            //     navigate(props.target?.uri);
            //   }
            //   props.onClick(event);
            // }}
          >
            {props.text}
            {props.childItems && props.childItems.length > 0 ? (
              <img src={downArrow} />
            ) : (
              ""
            )}
          </h3>
        </a>
      )}

      {/* Children */}
      {props.childItems && props.childItems.length > 0 && (
        <div className={styles.navMenuSublistContainer}>
          <ul className={styles.navMenuSublist}>
            {props.childItems.map((childItem) => {
              return (
                <li className={styles.linksHidden}>
                  {childItem.target?._type === "url" && (
                    <a
                      href={childItem.target?.uri}
                      target="_blank"
                      rel="noreferrer"
                      onClick={props.onClick}
                    >
                      <h3>{childItem.text}</h3>
                    </a>
                  )}
                  {!(childItem.target?._type === "url") && (
                    <a
                      href={childItem.target?.uri}
                      // target="_blank"
                      onClick={props.onClick}
                    >
                      <h3
                        // onClick={(event) => {
                        //   if (childItem.target?._type === "path") {
                        //     navigate(childItem.target?.uri);
                        //   }
                        //   props.onClick(event);
                        // }}
                      >
                        {childItem.text}
                      </h3>
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </li>
  );
}

type NavMenuProps = {
  isExpanded: boolean;
  items: NavMenuItemProps[];
};

function NavMenu(props: NavMenuProps) {
  return (<>
    <div
      className={props.isExpanded ? `${styles.navMenu} ${styles.navMenuExpanded}` : styles.navMenu}
    >
      <ul className={`${styles.navMenuList} ${styles.navMenuListRoot}`}>
        <div className={styles.navButtonContainer}>
          <AuthenticationButtons />
        </div>
        {props.items.map((item) => {
          return <NavMenuItem {...item} />;
        })}
      </ul>
    </div>
  </>);
}

const Topbar = () => {
  const auth0User = useAuth0();
  const navigate = useNavigate();
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const navMenuItems: NavMenuItemProps[] = [
    // {
    //   text: "Calculators",
    //   onClick: () => { setIsNavExpanded(false) },
    //   target: {
    //     _type: "path",
    //     uri: "/",
    //   },
    //   childItems: [
    //     {
    //       text: "JoiningIt™",
    //       target: {
    //         _type: "path",
    //         // todo?: Add component-level support for logged in vs logged out targets
    //         uri: auth0User.isAuthenticated ? "/JoinIt" : "/WelcomeJoinIt",
    //       },
    //     },
    //     {
    //       text: "FindingIt™",
    //       target: {
    //         _type: "path",
    //         uri: auth0User.isAuthenticated ? "/FindIt" : "/WelcomeFindIt",
    //       },
    //     },
    //     {
    //       text: "Torque Calculator™",
    //       target: {
    //         _type: "path",
    //         uri: auth0User.isAuthenticated ? "/TorqueCalculator" : "/WelcomeTorquingIt",
    //       },
    //     },

    //     {
    //       text: "ClipIt™",
    //       target: {
    //         _type: "path",
    //         uri: "/FindingClips",
    //       },
    //     },
    //   ],
    // },
    {
      text: "Products",
      onClick: () => { setIsNavExpanded(false) },
      target: {
        _type: "path",
        uri: "/",
      },
      childItems: [
        // {
        //   text: "DesigningIt™",
        //   target: {
        //     _type: "path",
        //     uri: auth0User.isAuthenticated ? "/DesignIt" : "/WelcomeDesignIt",
        //   },
        // },
        {
          text: "JoiningIt™",
          target: {
            _type: "path",
            uri: "/Products#DigitalBrandExpert",
          },
        },
        {
          text: "FindingIt™",
          target: {
            _type: "path",
            uri: "/Products#PartFinder",
          },
        },

        {
          text: "LearningIt™",
          target: {
            _type: "path",
            uri: "/Products#DigitalLearning",
          },
        },
      ],
    },
    {
      text: "LearningIt™",
      onClick: () => { setIsNavExpanded(false) },
      target: {
        _type: "path",
        uri: "/LearnIt"
      },
      childItems: [
        {
          text: "Glossary",
          target: {
            _type: "path",
            uri: "/LearnIt",
          },
        },
        {
          text: "Courses",
          target: {
            _type: "path",
            uri: "/IntroCourses",
          },
        },
        // {
        //   text: "Mini-Courses",
        //   target: {
        //     _type: "path",
        //     uri: "/MiniCourses",
        //   },
        // },
      ],
    },
    {
      text: "Demo video",
      onClick: () => { setIsNavExpanded(false) },
      target: {
        _type: "path",
        uri: "/Demo",
      },
      // childItems: [
      //   {
      //     text: "Demo Video",
      //     target: {
      //       _type: "path",
      //       uri: `/Demo`,
      //     },
      //   },
      //   {
      //     text: "Fastening Fixes Blog",
      //     target: {
      //       _type: "url",
      //       uri: "https://blog.findingfasteners.com",
      //     },
      //   },
      // ],
    },
    {
      text: "Who we are",
      onClick: () => { setIsNavExpanded(false) },
      target: {
        _type: "path",
        uri: "/AboutUs",
      },
      childItems: [
        {
          text: "Our Team",
          target: {
            _type: "path",
            uri: "/AboutUs",
          },
        },
        {
          text: "Contact Us",
          target: {
            _type: "path",
            uri: "/ContactUs",
          },
        },
        {
          text: "Mission Statement",
          target: {
            _type: "path",
            uri: "/MissionStatement",
          },
        },
      ],
    },
  ];

  return (<>
    <nav className={styles.navContainer}>
      <FesLogo
        className={styles.navLogo}
        onClick={() => {
          navigate("/");
        }}
      />
      <NavMenu items={navMenuItems} isExpanded={isNavExpanded} />
      <div className={styles.navButtonContainer}>
        <AuthenticationButtons />
      </div>
      <button
        className={styles.hamburger}
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        {/* Hamburger button icon */}
        {isNavExpanded
          ? <svg className="w-6 h-6" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          : <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        }
      </button>
    </nav>
    <div
      style={{ display: isNavExpanded ? "" : "none" }}
      className={styles.navMenuListUnderlay}
      onClick={() => { setIsNavExpanded(false) }}
    />
  </>);
};

export default Topbar;


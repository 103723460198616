import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Button";
import Loading from "../Loading";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

const AuthenticationButtons = () => {
  const navigate = useNavigate()
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }
  if (isAuthenticated) {
    return (
      <>
        <Button
          size="sm"
          onClick={() => { navigate('/Account') }}
        >
          My Account
        </Button>
        <LogoutButton />
      </>
    );
  } else {
    return (
      <>
        <a
          href="/ContactUs"
        >
          <Button
            size="sm"
            textColor={{ color: 'white' }}
            backgroundColor={{ background: '#000000' }}
            border={{ border: 'solid #A9A9A9 6px' }}
          >
            Get Started
          </Button>
        </a>
        <LoginButton />
      </>
    );
  }
};

export default AuthenticationButtons;

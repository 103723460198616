import styles from "../styles/productsLandingPage.module.scss";
import HomeIcon from "../images/HomeIcon.svg";
import ProductsPageFindingIt from "../images/ClientsBlock4LeftImage.png";
import ProductsPageJoiningIt from "../images/ProductsPageJoiningIt.png";
import ProductsPageLearningIt from "../images/ProductsPageLearningIt.png";
import Products1 from "../images/Products1.png";
import Products2 from "../images/Products2.png";

export function Products() {
  return (
    <div className={styles.clientsContainer}>
      <a href="/ContactUs" className={styles.anchor}>
        <button className={styles.demoButtonConstant}>GET A DEMO</button>
      </a>
      <div className={styles.block1}>
        <div className={styles.block1Left}>
          <h2 className={styles.block1Header}>Products</h2>
          <p className={styles.block1Text}>
            We offer 3 options to grow your digital presence and align your
            solutions for decades
          </p>
          <p className={styles.block1Text2}>
            Digital brand experts allow you to answer in real time, and not lose
            the order for the future
          </p>
          <a href="/ContactUs" className={styles.anchor}>
            <button className={styles.demoButton}>GET A DEMO</button>
          </a>
        </div>
        <div className={styles.block1Right}>
          <img alt="" src={Products1} className={styles.productImage1} />
          <img alt="" src={Products2} className={styles.productImage2} />
        </div>
      </div>
      <div id="PartFinder" className={styles.block2}>
        <div className={styles.block2Left}>
          <img
            src={ProductsPageFindingIt}
            alt="laptop"
            className={styles.block2LeftImage}
          />
        </div>
        <div className={styles.block2Right}>
          <h3 className={styles.block2RightHeader}>
            Part Finder - FES - FindingIt™
          </h3>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              FindingIt is our educational digital brand expert for your
              products
            </p>
          </div>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              Instead of clunky online searches or worst yet, PDF catalogs,
              create a digital part search for all the solutions in your system
            </p>
          </div>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              Give your sales, design, and distribution teams a digital way to
              search through all your current parts and avoid costly one-offs
            </p>
          </div>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              Use this online search tool as a digital quote tool or part
              request to capture and track every request in your system
            </p>
          </div>
        </div>
      </div>
      <div id="DigitalBrandExpert" className={styles.block3}>
        <div className={styles.block3Left}>
          <h3 className={styles.block3LeftHeader}>
            Digital Brand Expert - FES - JoiningIt™
          </h3>
          <div className={styles.block3LeftTextWrapper}>
            <img src={HomeIcon} alt="icon" className={styles.homeIcon} />
            <p className={styles.block3LeftText}>
              This is a one of kind SaaS tool that asks the designer what they
              are planning to build
            </p>
          </div>
          <div className={styles.block3LeftTextWrapper}>
            <img src={HomeIcon} alt="icon" className={styles.homeIcon} />
            <p className={styles.block3LeftText}>
              By asking them expert questions with visual guides and pro tips,
              you will avoid costly one-offs and mistakes due to ME designers'
              knowledge gaps
            </p>
          </div>
          <div className={styles.block3LeftTextWrapper}>
            <img src={HomeIcon} alt="icon" className={styles.homeIcon} />
            <p className={styles.block3LeftText}>
              Track every new opportunity request and capitalize on new markets,
              common efforts, and opportunities to grow sales or reduce costs
            </p>
          </div>
        </div>
        <div className={styles.block3Right}>
          <img
            src={ProductsPageJoiningIt}
            alt="laptop"
            className={styles.block3RightImage}
          />
        </div>
      </div>
      <div id="DigitalLearning" className={styles.block4}>
        <div className={styles.block4Left}>
          <img
            src={ProductsPageLearningIt}
            alt="laptop"
            className={styles.block4LeftImage}
          />
        </div>
        <div className={styles.block4Right}>
          <h3 className={styles.block4RightHeader}>
            DIGITAL LEARNING - FES - LEARNINGIT™
          </h3>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              Online learning is key to any OEM, Vendor, or distributors success
            </p>
          </div>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              We offer pro-tips and education throughout our products, but we
              also have a complete course lineup to take now
            </p>
          </div>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              We can also create any course you want in under a week from
              new-hire training to product training for your customers
            </p>
          </div>
          <div className={styles.block2TextWrapper}>
            <img alt="" src={HomeIcon} className={styles.block4HomeIcon} />
            <p className={styles.block2RightText}>
              Teach them how to use our digital tools with one quick 15 min
              online course
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

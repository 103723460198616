import styles from "../styles/clientsLandingPage.module.scss";
import ClientsIcon1 from "../images/evClients1.png";
import ClientsIcon2 from "../images/evClients2.png";
import ClientsIcon3 from "../images/evClients3.png";
import ClientsBlock3Image from "../images/evClientsImage.svg";
import ClientsBlock4LeftImage from "../images/ClientsBlock4LeftImage.png";
import ClientsBlock4RightImage from "../images/ClientsBlock4RightImage.png";
import HomeIcon from "../images/HomeIcon.svg";
import Clients1 from "../images/Clients1.png"
// import Clients2 from "../images/Clients2.png"
import Clients2 from "../images/Products1.png"

export function Clients() {
  return (
    <div className={styles.clientsContainer}>
      <a href="/ContactUs" className={styles.anchor}>
      <button className={styles.demoButtonConstant}>GET A DEMO</button>
      </a>
       <div className={styles.block1}>
        <div className={styles.block1Left}>
        <h2 className={styles.block1Header}>CLIENT OPPORTUNITIES</h2>
        <p className={styles.block1Text}>
           We provide digital brand experts to guide your designers, engineers,
          and sales teams
        </p>
        <a href="/ContactUs" className={styles.anchor}>
          <button className={styles.demoButton}>GET A DEMO</button>
        </a>
        </div>
        <div className={styles.block1Right}>
        <img src={Clients1} className={styles.productImage1}/>
        <img src={Clients2} className={styles.productImage2}/>
          
        
        </div>
      </div>
      {/* <div className={styles.block1}>
        <h2 className={styles.block1Header}>CLIENT OPPORTUNITIES</h2>
        <p className={styles.block1Text}>
          We provide digital brand experts to guide your designers, engineers,
          and sales teams
        </p>
        <a href="/ContactUs" className={styles.anchor}>
          <button className={styles.demoButton}>GET A DEMO</button>
        </a>
      </div> */}
      <div
        id="Manufacturers"
        className={[styles.block2, styles.scrollOffset5].join(" ")}
      >
        <h4 className={styles.block2Header}>EV MANUFACTURERS</h4>
        <div className={[styles.block2ItemWrapper, styles.block2ItemWrapper1].join(" ")}>
          <img src={ClientsIcon1} className={styles.block2Icon} alt="icon" />
          <hr className={styles.block2Line} />
          <h5 className={styles.block2ItemTitle}>
            Design challenges in electric vehicle manufacturing
          </h5>
          <p className={styles.block2ItemText}>
            Many designers lack essential knowledge in fasteners, assembly, and
            torque
          </p>
          <p className={styles.block2ItemText}>
            This knowledge gap often results in "McMaster" searches, errors, and
            expensive one-off solutions
          </p>
        </div>
        <div className={[styles.block2ItemWrapper, styles.block2ItemWrapper2].join(" ")}>
          <img src={ClientsIcon2} className={styles.block2Icon} alt="icon" />
          <hr className={styles.block2Line} />
          <h5 className={styles.block2ItemTitle}>
            Leveraging Digital Expertise for Cost Savings
          </h5>
          <p className={styles.block2ItemText}>
            Providing designers with a digital expert to navigate complex PLM
            tools can result in significant cost savings, potentially in the
            millions per year
          </p>
          <p className={styles.block2ItemText}>
            Our digital design expert incorporates all existing Catia designs
            and CAD prints into each design solution
          </p>
        </div>
        <div className={[styles.block2ItemWrapper, styles.block2ItemWrapper3].join(" ")}>
          <img src={ClientsIcon3} className={styles.block2Icon} alt="icon" />
          <hr className={styles.block2Line} />
          <h5 className={styles.block2ItemTitle}>
            Comprehensive Design Support and Future Innovations
          </h5>
          <p className={styles.block2ItemText}>
            We integrate the expertise of your veterans, GA to Materials, and vendors, offering digital DFMs or Pro tips tailored to specific design solutions
          </p>
          <p className={styles.block2ItemText}>
            A unified tool for design, assembly, and company-wide communication is invaluable, surpassing the cost of admission
          </p>
          <p className={styles.block2ItemText}>
            In the long term, envision a visual PLM search akin to a CRM interface, simplifying tasks for DREs and surpassing the usability of current systems
          </p>
        </div>
      </div>
      <div className={styles.block3}>
      <div
        id="Vendors"
        className={[styles.block3Left, styles.scrollOffset5].join(" ")}
      >
        <h3 className={styles.block3LeftTitle}>
          Vendors -{" "}
          <span className={styles.emphasisUnderline}>Digital FAE</span>
        </h3>
        <img className={styles.block3Image} src={ClientsBlock3Image} alt="laptop" />
      </div>
      <div className={styles.block3Right}>
        <h5 className={styles.block3RightHeader}>Unlocking Sales Potential</h5>
        <p className={styles.block3RightSubHeader}>
          Digital brand experts play a crucial role in maximizing your product's
          potential. Here's how:
        </p>
        <div className={styles.block3RightItemWrapper}>
          <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
          <p className={styles.block3RightText}>
            <span className={styles.block3RightTextBold}>
              Empowering Your Team:{" "}
            </span>
            Transform every new hire, representative, and distributor into an
            instant Subject Matter Expert (SME)
          </p>
        </div>
        <div className={styles.block3RightItemWrapper}>
          <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
          <p className={styles.block3RightText}>
            <span className={styles.block3RightTextBold}>
              Targeted Lead Generation:{" "}
            </span>
            Track every visitor to your site, providing your sales and
            distribution teams with qualified leads
          </p>
        </div>
        <div className={styles.block3RightItemWrapper}>
          <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
          <p className={styles.block3RightText}>
            <span className={styles.block3RightTextBold}>
              Strategic Resource Allocation:{" "}
            </span>
            Free up your engineering team from repetitive questions, allowing
            them to focus on projects that contribute to your bottom line
          </p>
        </div>
      </div>
      </div>
      <div id="Distributors" className={styles.block4}>
      <div
        className={[styles.block4TopLeft, styles.scrollOffset5].join(" ")}
      >
        {/* <div className={styles.block4TopLeftWrapper}> */}
          <h3 className={styles.block4TopLeftTitle}>Distributors</h3>
          <img
            className={styles.block5Images}
            src={ClientsBlock4LeftImage}
            alt="laptop"
          />
        {/* </div> */}
      </div>
      <div className={styles.block4TopRight}>
        {/* <div className={styles.block4TopRightWrapper}> */}
          <h5 className={styles.block4TopRightHeader}>
            Transform your sales teams into experts
          </h5>
          <div className={styles.block4TopRightItemWrapper}>
            <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
            <p className={styles.block4TopRightText}>
              Do you depend heavily on vendors to answer the "expert" questions?
            </p>
          </div>
          <div className={styles.block4TopRightItemWrapper}>
            <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
            <p className={styles.block4TopRightText}>
              Would you grow your business if your teams could answer questions
              in the field instantly?
            </p>
          </div>
          <div className={styles.block4TopRightItemWrapper}>
            <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
            <p className={styles.block4TopRightText}>
              With the digital brand expert, you can turn your sales teams into
              Subject Matter Experts (SMEs) overnight
            </p>
          </div>
          <div className={styles.block4TopRightItemWrapper}>
            <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
            <p className={styles.block4TopRightText}>
              Grow your business and gain more vendors by becoming the go-to
              experts for their products
            </p>
          </div>
        {/* </div> */}
      </div>
      </div>
      <div className={styles.block5}>
      <div className={styles.block5Left}>
        {/* <div className={styles.block4TopRightWrapper}> */}
          <h5 className={styles.block4TopRightHeader}>
            Why you should use FES.ai?
          </h5>
          <div className={styles.block4TopRightItemWrapper}>
            <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
            <p className={styles.block4TopRightText}>
              Establish an easy-to-use online quoting tool and a digital expert
              to guide your sales teams as well as your customers
            </p>
          </div>
          <div className={styles.block4TopRightItemWrapper}>
            <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
            <p className={styles.block4TopRightText}>
              Become the go-to source for all things technical and,
              consequently, the best place to buy a fastener
            </p>
          </div>
          <div className={styles.block4TopRightItemWrapper}>
            <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
            <p className={styles.block4TopRightText}>
              Train new hires with our online education program and seamlessly
              roll them out to your clients through our tools
            </p>
          </div>
        {/* </div> */}
      </div>
      <div className={styles.block5Right}>
        {/* <div className={styles.block4TopLeftWrapper}> */}
          <img
            className={styles.block5Images}
            src={ClientsBlock4RightImage}
            alt="laptop"
          />
        {/* </div> */}
      </div>
      </div>
      <div className={styles.block6}>
        <h2 className={styles.block6Header}>CLIENT OPPORTUNITIES</h2>
        <a href="/ContactUs" className={styles.anchor}>
          <button className={styles.block6DemoButton}>GET A DEMO</button>
        </a>
        <p className={styles.block6Text}>
          We provide digital brand experts to guide your designers, engineers,
          and sales teams
        </p>
      </div>
    </div>
  );
}

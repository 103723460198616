import React, {useEffect} from "react";
import styles from "../styles/_home.module.scss"


const Communizing = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    portalId: '21326007',
                    formId: 'b4ec7a5f-e46f-4852-8bc3-c90538f3cf1a',
                    target: '#hubspotForm'
                })
            }
        });
    }, []);

    return (
        
        <div style={{display: 'grid'}}>
            <div id="hubspotForm" className={styles.hubspotForm}></div>
        </div>
       
    );

}


export default Communizing
import styles from "../styles/_home.module.scss";
// import LearningItFeature from "../images/LearningItBlock4.svg";
import PartFinder from "../images/PartFinderComputerView.png";
import DigitalBrandExpert from "../images/ClippingitComputerView.png";
import VirtualLibrary from "../images/CoursesComputerView.png";
import PLMImage from "../images/PLM2.svg";
import HomeIcon from "../images/HomeIcon.svg";
import HomeIconDark from "../images/homeIconDark.png";
// import FESsvg from "../images/FES.svg";
import EVClientsImage from "../images/evClientsImage.png";
import VendorsImage from "../images/vendorsImage.png";
import DistributorsImage from "../images/smbDistributorsImage.png";
import Block6LeftImage from "../images/Block6Right.svg";
import { useNavigate } from "react-router-dom";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Carousel from "nuka-carousel";
// import ReactSimplyCarousel from 'react-simply-carousel';
import { useState } from "react";

const carouselImageSrcs = [PartFinder, DigitalBrandExpert, VirtualLibrary];

function Carousel({ activeImageIndex }: { activeImageIndex: number }) {
  // const isCurrent = carouselImageSrcs.at(activeImageIndex);
  // if (!isCurrent) throw new Error("Image not found");
  return (
    <>
      <div>
        {carouselImageSrcs.map((imageSrc, idx) => {
          return (
            <img
              src={imageSrc}
              alt="laptop"
              className={[
                styles.block4LeftImage,
                idx === activeImageIndex ? "" : styles.hidden,
              ].join(" ")}
            />
          );
        })}
      </div>
    </>
  );
}

export function Home2() {
  const navigate = useNavigate();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const incrementImageIndex = () => {
    if (activeImageIndex >= carouselImageSrcs.length - 1) {
      setActiveImageIndex(0);
      return;
    }
    setActiveImageIndex(activeImageIndex + 1);
  };

  return (
    <div className={styles.wrapper}>
       <a href="/ContactUs" className={styles.anchor}>
      <button className={styles.demoButtonConstant}>GET A DEMO</button>
      </a>
      <div className={styles.block1}>
        <h1 className={styles.heroHeader}>
          DON'T GET LOST IN THE MAZE OF YOUR SOLUTIONS, INTERNALLY AND EXTERNALLY
        </h1>
        <h4 className={styles.heroText}>
          We provide digital brand experts to guide your designers, engineers,
          sales teams, and distributors to the best solutions in your system
        </h4>
         <a href="/ContactUs" className={styles.anchor}>
        <button className={styles.demoButton}>GET A DEMO</button>
        </a>
        <a href="#WhyFES">
        <button className={styles.whyFesButton}>WHY FES ?</button>
        </a>
      </div>
      <div className={styles.block2Left}>
        <img className={styles.block2Image} src={PLMImage} alt="laptop" />
      </div>
      <div className={styles.block2right}>
        <h2 className={styles.block2RightHeader}>
          Why a{" "}
          <span className={styles.emphasisUnderline}>digital brand expert</span>{" "}
          matters
        </h2>
        <div className={styles.block2RightTextWrapper}>
          <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
          <p className={styles.block2RightText}>
            Capture more qualified leads instantly and acheive your revenue
            goals faster every day
          </p>
        </div>
        <div className={styles.block2RightTextWrapper}>
          <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
          <p className={styles.block2RightText}>
            Turn new hires into brand experts for your product overnight
          </p>
        </div>
        <div className={styles.block2RightTextWrapper}>
          <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
          <p className={styles.block2RightText}>
            Push common parts and avoid costly one-off's that lead to mistakes
          </p>
        </div>
        <div className={styles.block2RightTextWrapper}>
          <img src={HomeIcon} className={styles.homeIcon} alt="icon" />
          <p className={styles.block2RightText}>
            Use education and pro-tips to guide designers to the best path for
            their products
          </p>
        </div>
      </div>
      <div id="WhyFES" className={[styles.block3, styles.scrollOffset5].join(" ")}>
        <h2 className={styles.whyFesHeader}>Why FES.ai</h2>
        <a className={styles.anchor} href="/Clients#Manufacturers">
          <div
            className={[
              styles.whyFesItemWrapper1,
              styles.whyFesItemWrapper,
            ].join(" ")}
          >
            <h5 className={styles.whyFesItemHeader}>MANUFACTURERS</h5>
            <img src={HomeIcon} className={styles.homeIconBlock3} alt="icon" />
            <p className={styles.whyFesText}>
              Close the knowledge gap left by ME degrees, and improve the
              quality and safety of your designs
            </p>
            <img src={HomeIcon} className={styles.homeIconBlock3} alt="icon" />
            <p className={styles.whyFesText}>
              Guide DREs towards common solutions with less cost and
              obsolescence
            </p>
          </div>
        </a>
        <a href="/Clients#Distributors" className={styles.anchor}>
          <div
            className={[
              styles.whyFesItemWrapper2,
              styles.whyFesItemWrapper,
            ].join(" ")}
          >
            <h5 className={styles.whyFesItemHeader}>DISTRIBUTORS</h5>
            <img src={HomeIcon} className={styles.homeIconBlock3} alt="icon" />
            <p className={styles.whyFesText}>
              Never rely on the vendor again for technical answers about the
              brands you support
            </p>
            <img src={HomeIcon} className={styles.homeIconBlock3} alt="icon" />
            <p className={styles.whyFesText}>
              Turn you sales teams into brand experts and build trust with your
              customer therefore increasing your revenues exponentially
            </p>
          </div>
        </a>
        <a href="/Clients#Vendors" className={styles.anchor}>
          <div
            className={[
              styles.whyFesItemWrapper3,
              styles.whyFesItemWrapper,
            ].join(" ")}
          >
            <h5 className={styles.whyFesItemHeader}>VENDORS</h5>
            <img src={HomeIcon} className={styles.homeIconBlock3} alt="icon" />
            <p className={styles.whyFesText}>
              Turn new hires into SMEs overnight, and become a better partner to
              manufacturers
            </p>
            <img src={HomeIcon} className={styles.homeIconBlock3} alt="icon" />
            <p className={styles.whyFesText}>
              Answer technical questions instantly online and capture more
              qualified leads
            </p>
          </div>
        </a>
      </div>
      <div className={styles.block4Left}>
        <Carousel activeImageIndex={activeImageIndex} />
      </div>
      <div className={styles.block4Right}>
        <div className={styles.block4RightWrapper}>
          <h3 className={styles.block4RightHeader}>
            <span className={styles.featureEmphasisUnderline}>Products</span>
          </h3>
          <div
            className={styles.block4RightTextWrapper}
            onMouseEnter={() => {
              setActiveImageIndex(0);
            }}
          >
            <img className={styles.fesImage} src={HomeIcon} alt="fes icon" />
            <a href="/Products#PartFinder" className={styles.anchor}>
            <div
              className={styles.block4FeatureWrapper}
              // onTouchMoveCapture={() => { setActiveImageIndex(0) }}
            >
              <h3 className={styles.block4FeatureHeader}>Part Finder</h3>
              <p>
                FES-FindingIt™ is a smart part finder for the products in your
                system that guides sales and engineering to common parts
              </p>
            </div>
            </a>
          </div>
          <div

            className={styles.block4RightTextWrapper}
            onMouseEnter={() => {
              setActiveImageIndex(1);
            }}
          >
            <img className={styles.fesImage} src={HomeIcon} alt="fes icon" />
            <a href="/Products#DigitalBrandExpert" className={styles.anchor}>
            <div  className={styles.block4FeatureWrapper}>
              <h3 className={styles.block4FeatureHeader}>
                Digital Brand Expert
              </h3>
              <p>
                FES-JoiningIt™ is a virtual field application engineer that
                answers technical questions in seconds and finds the best
                solution for your application
              </p>
            </div>
            </a>
          </div>
          <div
            className={styles.block4RightTextWrapper}
            onMouseEnter={() => {
              setActiveImageIndex(2);
            }}
          >
            <img className={styles.fesImage} src={HomeIcon} alt="fes icon" />
            <a href="/Products#DigitalLearning" className={styles.anchor}>
            <div className={styles.block4FeatureWrapper}>
              <h3 className={styles.block4FeatureHeader}>Virtual Library</h3>
              <p>
                FES-LearningIt™ is our digital learning environment with pro tips,
                veteran advice, and online courses to train your teams to be the
                best version of their roles
              </p>
            </div>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.block5}>
        <h2 className={styles.block5Header}>Client Types</h2>
        <a href="/Clients#Manufacturers" className={styles.anchor}>
          <div
            className={[
              styles.block5ItemWrapper1,
              styles.block5ItemWrapper,
            ].join(" ")}
          >
            <img
              src={EVClientsImage}
              className={styles.clientsImage}
              alt="car diagram"
            />

            <div className={styles.block5TextWrapper}>
              <div className={styles.block5IconWrapper}>
                <img
                  src={HomeIconDark}
                  className={styles.homeIconDark}
                  alt="icon"
                />
                <h5 className={styles.block5Text}>EV Clients</h5>
              </div>
              <p className={styles.clientsText}>
                Cars, Planes, ATV’s, Trucks, and Trains, we support EV clients
                as a company
              </p>
            </div>
          </div>
        </a>
        <a href="/Clients#Vendors" className={styles.anchor}>
          <div
            className={[
              styles.block5ItemWrapper2,
              styles.block5ItemWrapper,
            ].join(" ")}
          >
            <img
              src={VendorsImage}
              className={styles.clientsImage}
              alt="fastener diagram"
            />

            <div className={styles.block5TextWrapper}>
              <div className={styles.block5IconWrapper}>
                <img
                  src={HomeIconDark}
                  className={styles.homeIconDark}
                  alt="icon"
                />
                <h5 className={styles.block5Text}>Vendors</h5>
              </div>
              <p className={styles.clientsText}>
                OEM Experts – Internal and External Brand Experts that are
                crucial to Innovation
              </p>
            </div>
          </div>
        </a>
        <a href="/Clients#Distributors" className={styles.anchor}>
          <div
            className={[
              styles.block5ItemWrapper3,
              styles.block5ItemWrapper,
            ].join(" ")}
          >
            <img
              src={DistributorsImage}
              className={styles.clientsImage}
              alt="distribution diagram"
            />
            <div className={styles.block5TextWrapper}>
              <div className={styles.block5IconWrapper}>
                <img
                  src={HomeIconDark}
                  className={styles.homeIconDark}
                  alt="icon"
                />
                <h5 className={styles.block5Text}>SMB Distributors</h5>
              </div>
              <p className={styles.clientsText}>
                Put a digital engineer in front of your customers, and capture
                more leads with a digital SME (Subject Matter Expert)
              </p>
            </div>
          </div>
        </a>
      </div>
      <div className={styles.block6}>
      <div className={styles.block6Left}>
        <h3 className={styles.block6Header}>
          Visual searches are the key to better designs and solutions for the
          brands you support
        </h3>
        <p>
          Using pictures of the application and where your parts are used in
          that application teaches the client and your sales teams when they can
          and can’t use your products
        </p>
        <p>
          With a picture of the program/application and details of that
          application, engineers and sales teams will remember that solution
          forever
        </p>
        <a href="/Demo" className={styles.anchor}>
        <button className={styles.block6DemoButton}>DEMO VIDEO</button>
        </a>
      </div>
      <div className={styles.block6Right}>
        <img src={Block6LeftImage} alt="laptop" />
      </div>
      </div>
    </div>
  );
}

export default Home2;

import Tooltip, { PositionType, TooltipPrimitive } from "@atlaskit/tooltip";
import styled from "@emotion/styled/";
import { useNavigate } from "react-router-dom";
import styles from '../../styles/_tooltip.module.scss';

export type FfTooltipProps = {
  tooltipContent: React.ReactNode,
  tooltipDecorator?: 'icon-pro-tip',
  link?:
  | {
    targetLocation: 'local',
    path: string
  }
  | {
    targetLocation: 'external',
    path: string
  }
  position?: PositionType,
  children: React.ReactNode,
}

export const FfTooltip = ({ tooltipContent, tooltipDecorator, link, position, children }: FfTooltipProps) => {
  // children most be an actual JSX element; cannot be string only.
  // ❌ <FfTooltip ... >title</FfTooltip>
  // ✅ <FfTooltip ... ><p>title</p></FfTooltip>

  const navigate = useNavigate();

  const styledTooltip = styled(TooltipPrimitive)`
    color: black;
    font-style: italic;
    background-color: white;
    border: solid 4px #266783;
    width: 350px;
    padding: .75rem;
    text-align: left;
    border-radius: 0.25rem;
  `

  const wrappedTooltipContent = (tooltipDecorator == 'icon-pro-tip')
    ? <>
      <div
        className={`atlas-tooltip-inner icon-pro-tip`}
      >
        <span className={styles.tooltipProTip}>Pro Tip: </span>
        {tooltipContent}
      </div>
    </>
    : <>
      <div
        className={'atlas-tooltip-inner'}
      >
        {tooltipContent}
      </div>
    </>

  const linkedWrappedTooltipContent = (link == undefined)
    ? wrappedTooltipContent
    : (link.targetLocation == 'external')
      ? <>
        <a href={link.path} target='_blank'>
          {wrappedTooltipContent}
        </a>
      </>
      : <a onClick={() => { navigate(link.path) }}>
        {wrappedTooltipContent}
      </a>

  return <>
    <div className={styles.tooltipTriggerContentWrapper}>
      <Tooltip
        content={linkedWrappedTooltipContent}
        component={styledTooltip}
        position={position}
      >
        {children}
      </Tooltip>
    </div>
  </>
}
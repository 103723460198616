import { SVGProps } from "react"

const FesLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 109.87" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={17.84}
        x2={49.08}
        y1={63.85}
        y2={63.85}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#6ce4d7" />
        <stop offset={0.01} stopColor="#67dfd7" />
        <stop offset={0.07} stopColor="#54c9dc" />
        <stop offset={0.14} stopColor="#47badf" />
        <stop offset={0.22} stopColor="#3fb0e1" />
        <stop offset={0.34} stopColor="#3daee2" />
        <stop offset={0.95} stopColor="#4a5f99" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={50.44}
        x2={83.24}
        y1={45.51}
        y2={45.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#6ce4d7" />
        <stop offset={0.17} stopColor="#68dfd7" />
        <stop offset={0.36} stopColor="#5dd3da" />
        <stop offset={0.54} stopColor="#4bbede" />
        <stop offset={0.66} stopColor="#3daee2" />
        <stop offset={0.95} stopColor="#4a5f99" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={53.07}
        x2={73.84}
        y1={41.35}
        y2={41.35}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#6ce4d7" />
        <stop offset={0.19} stopColor="#69e1d7" />
        <stop offset={0.32} stopColor="#61d7d9" />
        <stop offset={0.44} stopColor="#53c7dc" />
        <stop offset={0.55} stopColor="#3fb1e1" />
        <stop offset={0.57} stopColor="#3daee2" />
        <stop offset={0.95} stopColor="#4a5f99" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={46.37}
        x2={83.28}
        y1={69.44}
        y2={69.44}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#4a5f99" />
        <stop offset={0.1} stopColor="#4867a0" />
        <stop offset={0.26} stopColor="#447eb5" />
        <stop offset={0.46} stopColor="#3ea3d7" />
        <stop offset={0.51} stopColor="#3daee2" />
        <stop offset={1} stopColor="#6ce4d7" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={36.81}
        x2={36.81}
        y1={19.97}
        y2={53.98}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#6ce4d7" />
        <stop offset={0.62} stopColor="#43b5e0" />
        <stop offset={0.71} stopColor="#3daee2" />
        <stop offset={0.77} stopColor="#3da9de" />
        <stop offset={0.84} stopColor="#3f9dd2" />
        <stop offset={0.91} stopColor="#4389bf" />
        <stop offset={0.97} stopColor="#476ca5" />
        <stop offset={1} stopColor="#4a5f99" />
      </linearGradient>
    </defs>
    <text
      style={{
        fill: "#fff",
        fontFamily: "OpenSans,&quot",
        fontSize: 15,
        letterSpacing: "-.01em",
      }}
      transform="translate(97.31 82.02)"
    >
      <tspan x={0} y={0}>
        {"Guiding to the Right Solution Every Time"}
      </tspan>
    </text>
    <path
      d="M97.87 66.28h378.92"
      style={{
        fill: "none",
        stroke: "#4a5f99",
        strokeMiterlimit: 10,
      }}
    />
    <text
      style={{
        fontFamily: "Oswald-Bold,Oswald",
        fontSize: 32,
        fontWeight: 700,
        letterSpacing: 0,
        fill: "#fff",
      }}
      transform="translate(97.9 52.2)"
    >
      <tspan x={0} y={0}>
        {"Finding Engineered Solutions"}
      </tspan>
    </text>
    <path
      d="M49.08 85.23H17.84V42.47h2.57v40.19h28.67v2.57z"
      style={{
        fill: "url(#a)",
      }}
    />
    <path
      d="M83.24 71.56h-2.57V22.04l-30.23-.01v-2.57l32.8.01v52.09z"
      style={{
        fill: "url(#b)",
      }}
    />
    <path
      d="M66.48 29.03v2.57h4.79v19.51h-6.69V36.87H53.07v8.37h2.57v-5.8h6.37v14.21h.26v.03h11.57V29.03h-7.36z"
      style={{
        fill: "url(#c)",
      }}
    />
    <path
      d="M80.64 78.55v4.11h-31.7v-6.81h25.01V60.53H59.59v4.5h-4.04V53.66h-2.51V67.6h9.12v-4.5h9.22v10.18H46.37v11.95h36.91v-6.68h-2.64z"
      style={{
        fill: "url(#d)",
      }}
    />
    <path
      d="M55.74 31.35V19.47H17.88v15.97h2.57v-13.4h32.72v6.74H27.3v15.25h16.73V57.4h-4.64v-6.44H27.25v24.65h12.16v-9.2h-2.54v6.63h-7.05V53.53h7v6.44h9.78V41.51h-1.46v-.05H29.87V31.35h25.87z"
      style={{
        fill: "url(#e)",
      }}
    />
  </svg>
)

export default FesLogo
